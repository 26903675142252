<template>
  <div class="entrustChildForm">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      @update="update"
    >
      <el-form-item label="课程日期" prop="dayName">
        {{ form.dayName }}
      </el-form-item>

      <el-form-item label="课程时间" prop="timeDuring">
        {{ timeDuringMap[form.timeDuring] }}
      </el-form-item>

      <el-form-item label="昵称" prop="parentsName">
        {{ form.parentsName }}
      </el-form-item>
      <el-form-item label="手机号" prop="parentsPhone">
        {{ form.parentsPhone }}
      </el-form-item>
      <el-form-item label="活动评分" prop="grade">
        {{ scoreMap[form.grade] }}
      </el-form-item>

      <el-form-item label="反馈图片" prop="feedbackPicture">
        <v-upload :limit="6" disabled :imgUrls.sync="form.feedbackPicture" />
      </el-form-item>

      <el-form-item label="意见建议" prop="feedbacksSuggestion">
        <v-input
          :width="500"
          placeholder="请输入意见建议"
          v-model="form.feedbacksSuggestion"
          type="textarea"
          :maxlength="200"
          disabled
        ></v-input>
      </el-form-item>

      <el-form-item label="提交时间" prop="updateTime">
        {{ form.updateTime }}
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import { getFeedbackDetailUrl } from "./api.js";
import { timeDuringMap } from "./map.js";

export default {
  name: "partyMemberForm",
  data() {
    return {
      width: 250,
      submitConfig: {
        queryUrl: getFeedbackDetailUrl,
      },
      timeDuringMap,
      scoreMap: {
        1: "非常差",
        2: "差",
        3: "一般",
        4: "满意",
        5: "非常满意",
      },
      timeDuringOps: [
        { name: 1, value: "上午" },
        { name: 2, value: "下午" },
      ],
      form: {
        dayName: undefined,
        timeDuring: undefined,
        parentsName: undefined,
        parentsPhone: "",
        grade: "",
        feedbackPicture: "",
        feedbacksSuggestion: "",
        updateTime: "",
      },
    };
  },
  mounted() {
    const { id } = this.$route.query;
    if (id !== undefined) {
      this.$refs.formPanel.getData({ id });
    }
    this.$setBreadList("查看");
  },
  methods: {
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key] || this.form[key];
      });
    },
  },
};
</script>

<style lang="less" scoped>
.entrustChildForm {
  box-sizing: border-box;
  height: 100%;

  .areaTimes {
    display: flex;
    align-items: flex-end;

    padding: 20px 0;
    border-bottom: 1px solid #eee;
    .areaTimes-l {
      margin-right: 20px;
    }
    .areaTimes-r {
      height: 57px;
      /deep/ .v-button {
        margin-right: 10px;
      }
    }
    .item {
      display: flex;
      align-content: center;
      margin: 20px 0;
      .item-l {
        margin-right: 10px;
      }
      .item-r {
        display: flex;
      }
    }
  }
}
</style>
